<script>
  import layout from "@/components/art/LayoutError.vue";
  export default {
    components: { layout },
    methods: {
      onSubmit() {
        this.$router.push("/apps");
      },
    },
  };
</script>

<template>
  <layout @onSubmit="onSubmit">
    <template v-slot:title>
      Sin acceso.
    </template>
    <template v-slot:body>
      No tiene acceso a esta aplicación.
    </template>
  </layout>
</template>
